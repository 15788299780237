.connectedWallet button {
  display: inline-flex;
  align-items: center;
}

.connectedWallet svg {
  margin-left: 10px;
}

.connectedWallet svg path {
  fill: var(--white);
}

.connectedWallet button:hover path {
  fill: var(--primary);
}